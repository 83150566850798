@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.variant .variant-wrapper {
  border-bottom: 1px solid #707070 50;
  padding-bottom: 30px;
}
.variant .variant-wrapper-tabs {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #333c4b;
  background-color: #333c4b;
}
.variant .variant-wrapper-tabs-button-prev-icon, .variant .variant-wrapper-tabs-button-next-icon, .variant .variant-wrapper-tabs-button-prev-icon-hide, .variant .variant-wrapper-tabs-button-next-icon-hide {
  font-size: 30px;
}
.variant .variant-wrapper-tabs-button-prev-icon, .variant .variant-wrapper-tabs-button-next-icon {
  cursor: pointer;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
}
.variant .variant-wrapper-tabs-button-prev-icon:hover, .variant .variant-wrapper-tabs-button-next-icon:hover {
  color: #ffffff;
  color: white;
}
.variant .variant-wrapper-tabs-button-prev-icon-hide, .variant .variant-wrapper-tabs-button-next-icon-hide {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.1);
}
.variant .variant-wrapper-tabs-col {
  border-bottom: 2px solid #ffffff 82;
}
.variant .variant-wrapper-tabs-col a {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  color: #707070 !important;
  color: #707070 !important;
  background-color: #333c4b !important;
  background-color: #333c4b !important;
}
.variant .variant-wrapper-tabs-col a.active {
  border-bottom: 3px solid #ffffff;
  border-radius: 0;
  color: #ffffff !important;
  color: white !important;
}/*# sourceMappingURL=Variant.css.map */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.categoryCommercial-item {
  border: none !important;
}
.categoryCommercial-item .categoryCommercial-item-background {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #ffffff;
}
.categoryCommercial-item .categoryCommercial-item-primary-media {
  transition: all 0.4s linear;
}
.categoryCommercial-item .categoryCommercial-item-secondary-media {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.categoryCommercial-item .categoryCommercial-item-secondary-media:hover {
  opacity: 1;
}
.categoryCommercial-item .categoryCommercial-item-primary-media,
.categoryCommercial-item .categoryCommercial-item-secondary-media {
  border-radius: 0 !important;
}
.categoryCommercial-item .categoryCommercial-item-energy-efficiency-media {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 0;
  bottom: 10px;
}
.categoryCommercial-item .categoryCommercial-item-body-title {
  font-size: 15px;
  font-weight: 700;
}
.categoryCommercial-item .categoryCommercial-item-body-text {
  text-align: justify;
  font-size: 15px;
  color: #686868;
  color: #686868;
}
.categoryCommercial-item .categoryCommercial-item-body-text-clamping {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, 3);
  -webkit-box-orient: vertical;
  word-break: var(--word-break, "none");
  overflow: hidden;
}
/* .categoryCommercial-item .categoryCommercial-item-border-bottom {
  border-top: 1px solid #000000;
} */
/*# sourceMappingURL=CategoryCommercialItem.css.map */